import {
  GetPlacesQuery,
  PlaceCreationProvenance,
  PlaceFragment,
} from '@shared/generated/graphql';
import { Place } from '@shared/types/place';

export const placeFromFragment = (fragment: PlaceFragment): Place => {
  return {
    id: fragment.id,
    name: fragment.name ?? '',
    address: {
      addressOne: fragment.address?.addressOne ?? '',
      addressTwo: fragment.address?.addressTwo ?? '',
      city: fragment.address?.city ?? '',
      state: fragment.address?.state ?? '',
      zip: fragment.address?.zip ?? '',
      country: fragment.address?.country ?? '',
    },
    locationCode: fragment.locationCode ?? '',
    originator: {
      id: fragment.originator?.id ?? '',
      name: fragment.originator?.name ?? 'None',
    },
    tags: fragment.tags ?? [],
    aliases: fragment.aliases ?? [],
    creationProvenance:
      fragment.creationProvenance ?? PlaceCreationProvenance.UNKNOWN,
  };
};

export const placesFromFragment = (query: GetPlacesQuery): Place[] => {
  return query.places.edges.map(({ node }) => placeFromFragment(node));
};
