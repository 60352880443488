import { useToast } from '@shared/components/toast';
import { useSubmitOrderMutation } from '@shared/generated/graphql';
import {
  OrderSubmissionError,
  parseOrderSubmissionErrors,
} from '@shared/utils/orders/orderSubmissionErrors';

type SubmitOrderInput = {
  id: string;
  hasBeenSubmitted: boolean;
  primaryReference?: string;
};

type SubmitAllInput = {
  id: string;
  orders: SubmitOrderInput[];
};

export const useSubmitAllOrders = () => {
  const { sendToast } = useToast();

  const [submitOrder] = useSubmitOrderMutation();

  const submitAll = async (job: SubmitAllInput) => {
    let submissionCount = 0;
    const submissionErrors: OrderSubmissionError[] = [];

    await Promise.all(
      job.orders.map(async (o) => {
        try {
          if (!o.hasBeenSubmitted) {
            await submitOrder({
              variables: { input: { id: o.id } },
              refetchQueries: ['GetJobs'],
            });
            submissionCount++;
          }
        } catch (e) {
          const message = e instanceof Error ? e.message : 'Unknown error';
          submissionErrors.push({
            orderRef: o.primaryReference ?? o.id,
            description: message,
          });
        }
      })
    );

    if (submissionCount === 0 && submissionErrors.length === 0) {
      sendToast('No orders to submit', {
        variant: 'default',
        isDismissible: true,
      });
    } else if (submissionErrors.length === 0) {
      const orderOrOrders = submissionCount === 1 ? 'order' : 'orders';
      sendToast(`${submissionCount} ${orderOrOrders} sent to TMS`, {
        variant: 'success',
        isDismissible: true,
      });
    } else {
      const { failedOrderStr, description } =
        parseOrderSubmissionErrors(submissionErrors);
      sendToast(`Failed to submit ${failedOrderStr}`, {
        variant: 'error',
        isDismissible: true,
        description: description,
      });
    }
  };

  return {
    submitAll,
  };
};
