import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { TextArea } from '../react-hook-form';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineTextArea');
const el = makeElementClassNameFactory(ROOT);

interface InlineTextAreaProps<T> {
  isDisabled?: boolean;
  label: string;
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
}

export const InlineTextArea = <T extends FieldValues>(
  p: InlineTextAreaProps<T>
) => {
  const { watch, control } = useFormContext<T>();
  const value: string = watch(p.name);

  const onSave = () => {
    p.save(p.name);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const hasModifierKey = e.ctrlKey || e.metaKey;
    if (e.key === 'Enter' && hasModifierKey) {
      onSave();
    }
  };

  const placeholder = `Add ${p.label.toLowerCase()}`;

  return (
    <div className={clsx(ROOT)}>
      <div className={el`table`}>
        <div className={el`label`}>
          <Text type="body-sm">{p.label}</Text>
        </div>
        <div className={el`field-and-icon-wrapper`}>
          <TextArea
            isDisabled={p.isDisabled}
            aria-label={p.label}
            onKeyDown={handleKeyDown}
            control={control}
            name={p.name}
            onBlur={onSave}
            size="medium"
            inputWrapperClassName={clsx(
              el`input-wrapper`,
              value ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            transparentWithoutFocus
            placeholderClassName={clsx(
              el`placeholder`,
              value ? 'VALID' : 'INVALID',
              p.required && 'REQUIRED'
            )}
            placeholder={placeholder}
            className={el`field-wrapper`}
          />
          <FlowIcon fieldPath={p.name} />
        </div>
      </div>
    </div>
  );
};
