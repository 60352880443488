import { Text } from '@shared/components';
import { SlideOver } from '@shared/components/plugin-components';
import {
  productListToText,
  WorkflowSequence,
} from '@shared/components/workflow-sequence/WorkflowSequence';
import {
  GetWorkflowsQuery,
  useGetOrganizationProductsQuery,
  useGetWorkflowsQuery,
} from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';

const ROOT = makeRootClassName('FlowsSidePanel');
export const el = makeElementClassNameFactory(ROOT);

type Workflows = GetWorkflowsQuery['workflows']['edges'];

interface FlowsSidePanelProps {
  organizationId: string;
  originatorId?: string;
  orgWorkflows: Workflows;
  origWorkflows: Workflows;
}

const FlowsSidePanel = (p: FlowsSidePanelProps) => {
  // TODO(jacob): Will need to rework how we display these once we introduce
  // flow triggers.
  const productsResp =
    useGetOrganizationProductsQuery({
      variables: {
        id: p.organizationId,
      },
    }).data?.getOrganizationProducts ?? [];
  const productStr = productListToText(productsResp);

  const originator = p.origWorkflows[0]?.node.originator;
  return (
    <div className={el`flows-container`}>
      <Text isHeavy>Flows</Text>
      <p className="text-sm">
        The following flows will apply to new {`${productStr}`} created in
        Vooma.
      </p>
      <WorkflowSequence
        originator={null}
        workflows={p.orgWorkflows.map((workflow) => workflow.node)}
        products={productsResp}
      />
      {originator && (
        <WorkflowSequence
          originator={originator}
          workflows={p.origWorkflows.map((workflow) => workflow.node)}
          products={productsResp}
        />
      )}
    </div>
  );
};

// NOTE(jacob): The only way we should see this is if we try to display
// the flows side panel from an order or quote without an organization, which
// should be impossible in normal usage.
const NoOrgFlows = () => {
  return (
    <div className={el`flows-container`}>
      <Text isHeavy>No Flows To Display</Text>
      <p className="text-sm">No organization was provided.</p>
    </div>
  );
};

interface FlowsSidePanelContainerProps {
  organizationId?: string;
  originatorId?: string;
  showSidepanel: boolean;
  setShowSidepanel: (show: boolean) => void;
}

const FlowsSidePanelContainer = ({
  originatorId,
  organizationId,
  showSidepanel,
  setShowSidepanel,
}: FlowsSidePanelContainerProps) => {
  const orgWorkflowResp = useGetWorkflowsQuery({
    variables: {
      input: {
        organizationId: organizationId,
      },
    },
    pollInterval: 10 * 1000,
  });
  const orgWorkflows = orgWorkflowResp.data?.workflows.edges ?? [];

  const origWorkflowResp = useGetWorkflowsQuery({
    variables: {
      input: {
        originatorId: originatorId,
        organizationId: organizationId,
      },
    },
    pollInterval: 10 * 1000,
  });
  const origWorkflows = origWorkflowResp.data?.workflows.edges ?? [];

  return (
    <SlideOver
      title={''}
      show={showSidepanel}
      onClose={() => setShowSidepanel(false)}
      className={el`flows-slide-over`}
    >
      {organizationId ? (
        <FlowsSidePanel
          originatorId={originatorId}
          organizationId={organizationId}
          orgWorkflows={orgWorkflows}
          origWorkflows={origWorkflows}
        />
      ) : (
        <NoOrgFlows />
      )}
    </SlideOver>
  );
};

export default FlowsSidePanelContainer;
