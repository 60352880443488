import { Button, Popover, Text } from '@shared/components';
import { InlineTextField } from '@shared/components/inline-text-field/InlineTextField';
import useLoadInitialValues from '@shared/components/react-hook-form/useLoadInitialValues';
import { LaneHistoryFilters } from '@shared/hooks/useLaneHistoryFilters';
import { merge } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';

type RadiusEntryProps = {
  filters: LaneHistoryFilters;
  setFilters: (filters: LaneHistoryFilters) => void;
};

type RadiusFormData = {
  originRadius: string;
  destinationRadius: string;
};

export function RadiusEntry(p: RadiusEntryProps) {
  const originRadius =
    p.filters.laneFilter?.origin?.radiusMiles.toString() ?? '50';
  const destinationRadius =
    p.filters.laneFilter?.destination?.radiusMiles.toString() ?? '50';
  return (
    <div>
      <RadiusForm
        initialValues={{ originRadius, destinationRadius }}
        setFilters={p.setFilters}
        filters={p.filters}
      />
    </div>
  );
}

type RadiusFormProps = {
  initialValues: RadiusFormData;
  setFilters: (filters: LaneHistoryFilters) => void;
  filters: LaneHistoryFilters;
};
function RadiusForm(props: RadiusFormProps) {
  const methods = useForm<RadiusFormData>({
    defaultValues: props.initialValues,
  });
  useLoadInitialValues(methods.reset, props.initialValues);

  const onSaveOriginRadius = () => {
    const value = methods.getValues('originRadius');
    const newFilters = merge({}, props.filters, {
      laneFilter: { origin: { radiusMiles: parseFloat(value) } },
    });
    props.setFilters(newFilters);
  };

  const onSaveDestinationRadius = () => {
    const value = methods.getValues('destinationRadius');
    const newFilters = merge({}, props.filters, {
      laneFilter: { destination: { radiusMiles: parseFloat(value) } },
    });
    props.setFilters(newFilters);
  };

  const className = 'flex flex-row items-baseline gap-2 text-gray-500';
  const fieldClassName = 'w-[75px] h-[28px] items-center justify-center';
  return (
    <Popover
      content={
        <div className="flex flex-col rounded-lg p-[12px] pt-[20px]">
          <FormProvider {...methods}>
            <div className={className}>
              <InlineTextField
                fieldWrapperClassName={fieldClassName}
                name="originRadius"
                save={onSaveOriginRadius}
                required={false}
                type="number"
              />
              <Text type="body-xs">miles of origin</Text>
            </div>
            <div className={className}>
              <InlineTextField
                fieldWrapperClassName={fieldClassName}
                name="destinationRadius"
                save={onSaveDestinationRadius}
                required={false}
                type="number"
              />
              <Text type="body-xs">miles of destination</Text>
            </div>
          </FormProvider>
        </div>
      }
    >
      <Button variant="tertiary" className="mb-2">
        Change search radius
      </Button>
    </Popover>
  );
}
