export enum FeatureFlagName {
  QUOTE_OPPORTUNITIES_VIEW = 'QUOTE_OPPORTUNITIES_VIEW',
  QUOTE_TRAIL = 'QUOTE_TRAIL',
  QUOTE_FREIGHT_MODE_FILTER = 'QUOTE_FREIGHT_MODE_FILTER',
  FORWARDED_EMAIL_CLASSIFICATION = 'FORWARDED_EMAIL_CLASSIFICATION',
  DAT_RATE_VIEW_AUTOMATION = 'DAT_RATE_VIEW_AUTOMATION',
  DAT_LOAD_SEARCH_AUTOMATION = 'DAT_LOAD_SEARCH_AUTOMATION',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  QUOTE_UPDATE_FROM_TMS_IMPORT = 'QUOTE_UPDATE_FROM_TMS_IMPORT',
  ENTITY_ASSOCIATION = 'ENTITY_ASSOCIATION',
  BUILD_ORDER_BUTTON = 'BUILD_ORDER_BUTTON',
  BUILD_QUOTE_BUTTON = 'BUILD_QUOTE_BUTTON',
  SHOW_THREAD_MESSAGE_CLASSIFICATIONS = 'SHOW_THREAD_MESSAGE_CLASSIFICATIONS',
  ORDER_PRESUBMIT_VALIDATION = 'ORDER_PRESUBMIT_VALIDATION',
  QUOTES_CONDITIONAL_AUTO_REPLY = 'QUOTES_CONDITIONAL_AUTO_REPLY',
  SCHEDULING = 'SCHEDULING',
  INCORPORATE_EXTERNAL_DATA_ON_ORDER_BUILD = 'INCORPORATE_EXTERNAL_DATA_ON_ORDER_BUILD',
  SHIPMENT_HISTORY = 'SHIPMENT_HISTORY',
  PAUSE_MESSAGE_CLASSIFICATION = 'PAUSE_MESSAGE_CLASSIFICATION',
  EMAIL_CONVERSATIONS = 'EMAIL_CONVERSATIONS',
}
