import { useOrganizationSettingsQuery } from '@shared/generated/graphql';
import { OrganizationSettings } from '@shared/types/organization';

type UseOrganizationSettingsOptions = {
  id: string;
  pollIntervalMs?: number;
};

type UseOrganizationSettingsState = {
  settings?: OrganizationSettings;
  isLoading: boolean;
};

type UseOrganizationSettingsActions = {
  // add any actions the hook provides
};

export type UseOrganizationSettingsValue = UseOrganizationSettingsState &
  UseOrganizationSettingsActions;

const useOrganizationSettings = (
  props: UseOrganizationSettingsOptions
): UseOrganizationSettingsValue => {
  const { id } = props;
  const { data, loading } = useOrganizationSettingsQuery({
    variables: {
      id,
    },
    ...(props?.pollIntervalMs && { pollInterval: props.pollIntervalMs }),
  });

  const settingsData = data?.organizationById?.settings;
  const settings = settingsData
    ? {
        emailScanning: {
          enabled: settingsData.emailScanning?.enabled ?? false,
          allowList: settingsData.emailScanning?.allowList ?? [],
          blockList: settingsData.emailScanning?.blockList ?? [],
          allowContacts: settingsData.emailScanning?.allowContacts ?? false,
        },
        front: settingsData.front,
      }
    : undefined;

  return {
    settings,
    isLoading: loading,
  };
};

export default useOrganizationSettings;
