import FilterChip from '@shared/components/filter-chip/FilterChip';
import { LaneHistoryFilters } from '@shared/hooks/useLaneHistoryFilters';
import { formatOption } from 'clerk_common/stringification/options';

type FilterChipsProps = {
  filters: LaneHistoryFilters;
  setFilters: (filters: LaneHistoryFilters) => void;
};

export function FilterChips(p: FilterChipsProps) {
  const className = 'w-fit border-[1px] border-gray-500 bg-white';
  const hasOriginatorFilter = p.filters.originatorIds?.length;
  const hasEquipmentFilter = p.filters.equipmentTypes?.length;

  if (!hasOriginatorFilter && !hasEquipmentFilter) {
    return null;
  }

  return (
    <div className="flex flex-col gap-[12px]">
      <div className="flex flex-row flex-wrap items-start gap-2 pb-[16px]">
        {hasOriginatorFilter && (
          <FilterChip
            className={className}
            label=""
            options={['This customer']}
            onDismiss={() =>
              p.setFilters({ ...p.filters, originatorIds: undefined })
            }
          />
        )}
        {hasEquipmentFilter && (
          <FilterChip
            className={className}
            label="Equipment"
            options={p.filters.equipmentTypes?.map(formatOption) ?? []}
            onDismiss={() =>
              p.setFilters({ ...p.filters, equipmentTypes: undefined })
            }
          />
        )}
      </div>
    </div>
  );
}
