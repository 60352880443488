import { DismissButton, Tooltip } from '@shared/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@shared/utils';
import clsx from 'clsx';
import { ReactElement } from 'react';

const getDisplayName = (options: string[]) =>
  options.length > 1
    ? `${options[0]} + ${options.length - 1} more...`
    : options[0];

export type FilterChipProps = StyleProps & {
  label: string;
  options: string[];
  tooltipContent?: string;
  onDismiss?: () => void;
};

const ROOT = makeRootClassName('FilterChip');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function FilterChip(props: FilterChipProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const getFilterChip = () => (
    <div className={clsx(ROOT, p.className)}>
      <span className={el`label`}>{p.label}</span>
      <span className={el`options`}>{getDisplayName(p.options)}</span>

      <DismissButton
        onPress={p.onDismiss}
        size="xs"
        className={el`dismiss-button`}
      />
    </div>
  );

  return props.tooltipContent ? (
    <Tooltip content={props.tooltipContent} isInstant>
      {getFilterChip()}
    </Tooltip>
  ) : (
    getFilterChip()
  );
}
export default FilterChip;
