import { Button, CopyButton } from '@shared/components';
import { isNil } from 'lodash';
import { MdOutlineOpenInNew } from 'react-icons/md';

export interface ExternalLinkBaseProps {
  externalLink: string | null;
  externalId: string | null;
  handleExternalLinkPress: () => void;
  handleExternalIdPress: () => void;

  externalLinkTooltip?: string;
  externalLinkButtonText?: string;

  copyButtonTooltip?: string;
  copyButtonText?: string;
}

function ExternalResourceBase({
  externalLink,
  externalId,
  handleExternalLinkPress,
  handleExternalIdPress,
  externalLinkTooltip,
  externalLinkButtonText,
  copyButtonTooltip,
  copyButtonText,
}: ExternalLinkBaseProps) {
  if (!externalId && !externalLink) {
    return null;
  }

  return !isNil(externalLink) ? (
    <Button
      variant={'tertiary'}
      onPress={() => {
        window.open(externalLink as string, '_blank', 'noopener,noreferrer');
        handleExternalLinkPress();
      }}
      icon={<MdOutlineOpenInNew className="text-brand-500" />}
      iconPosition="right"
      tooltip={externalLinkTooltip}
    >
      {externalLinkButtonText}
    </Button>
  ) : !isNil(externalId) ? (
    <div className="flex justify-center">
      <CopyButton
        textToCopy={externalId}
        label={copyButtonText}
        tooltip={copyButtonTooltip ?? 'Copy to clipboard'}
        variant="tertiary"
        size="medium"
        className="my-1"
        onCopy={handleExternalIdPress}
      />
    </div>
  ) : (
    <></>
  );
}

export interface ExternalLinkProps {
  externalLink: string | null;
  externalId: string | null;
  handleExternalLinkPress: () => void;
  handleExternalIdPress: () => void;
}

export function ExternalResource(p: ExternalLinkProps) {
  return (
    <ExternalResourceBase
      {...p}
      externalLinkButtonText="View submitted order in TMS"
      copyButtonText={`TMS ID: ${p.externalId}`}
    />
  );
}

export function ExternalResourceMinimal(
  p: Omit<ExternalLinkBaseProps, 'externalLinkButtonText' | 'copyButtonText'>
) {
  return <ExternalResourceBase {...p} />;
}
