import clsx from 'clsx';
import { Text } from '../text';

interface ItemsCountProps {
  count: number;
  className?: string;
  textClassName?: string;
}

export function ItemsCount({
  count,
  className,
  textClassName,
}: ItemsCountProps) {
  return (
    <div
      className={clsx(
        'flex h-4 min-w-4 items-center justify-center rounded-full bg-gray-400 text-white',
        className
      )}
    >
      <Text isHeavy type="body-xs" className={textClassName}>
        {count}
      </Text>
    </div>
  );
}
