import { z } from 'zod';

export enum QuoteAutoReplyScope {
  DISABLED = 'DISABLED',
  DRAFT = 'DRAFT',
  SEND = 'SEND',
}

export enum TeamMemberRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export const TeamSettingsSchema = z
  .object({
    quoteAutoReplyScope: z.nativeEnum(QuoteAutoReplyScope)?.optional(),
  })
  .strict();
export type TeamSettings = z.infer<typeof TeamSettingsSchema>;
