import { Button, Text } from '@shared/components';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useAPIContext } from '@shared/contexts/pluginContexts/APIContext';
import { useHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import { AuthTokenType } from '@shared/generated/graphql';
import { PiWarningCircleDuotone } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

const GOOGLE_AUTH_PATH = '/redirect/auth/google-api';

export const AuthorizeGmailNotice = () => {
  const { url } = useAPIContext();
  const navigate = useNavigate();
  const { me } = useMeContext();
  const { sendToast } = useToast();
  const { refetch } = useHasAuthorizedIntegration({
    type: AuthTokenType.GMAIL_API,
  });

  const initializeAuth = () => {
    const redirectUri = `${window.location.origin}/integrations`;
    const uri = `${url}${GOOGLE_AUTH_PATH}?redirectUri=${redirectUri}&userId=${me?.id}&client=web`;
    window.open(
      uri,
      'oauth window',
      'width=360,height=600,top=80,left=80,resizable,scrollbars=yes,status=1'
    );
    window.addEventListener('message', (event) => {
      if (event.data === 'googleOauthSuccess') {
        sendToast('You have successfully connected your Google account', {
          variant: 'success',
          isDismissible: true,
        });
        refetch();
        navigate('/');
      }
    });
  };

  return (
    <div className="flex flex-col gap-5 p-4">
      <div className="flex flex-row gap-2">
        <PiWarningCircleDuotone size={20} className="text-yellow-500" />
        <Text type="body-sm" isHeavy className="text-center text-gray-600">
          Vooma needs access to your Gmail
        </Text>
      </div>
      <Button variant="primary" className="w-full" onPress={initializeAuth}>
        Click to authorize
      </Button>
    </div>
  );
};
