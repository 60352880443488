import { ApolloError } from '@apollo/client';
import {
  GetLaneHistoryQuery,
  QuoteHistorySummaryFragment,
  ShipmentHistorySummaryFragment,
  useGetLaneHistoryQuery,
} from '@shared/generated/graphql';
import { Quote } from '@shared/types/quote';
import { useGetShipments } from './useGetShipments';
import { LaneHistoryFilters } from './useLaneHistoryFilters';

type UseGetQuotesState = {
  quotes: QuoteHistorySummaryFragment[];
  shipments: ShipmentHistorySummaryFragment[];
  loading?: boolean;
  error?: ApolloError;
};

const getHistory = (quote: Quote, data?: GetLaneHistoryQuery) => {
  // NOTE(parlato): Do not show a route history if the quote has not
  // geocoordinates
  if (!quote.routeGeoCoordinates) return [];

  return (
    data?.quotes.edges
      .map((edge) => edge.node)
      .filter((q) => q.id !== quote.id && Boolean(q.customerRate))
      .slice(0, 5) ?? []
  );
};

export const useLaneHistory = (
  quote: Quote,
  filters: LaneHistoryFilters
): UseGetQuotesState => {
  const { data, loading, error } = useGetLaneHistoryQuery({
    variables: {
      input: {
        organizationIds: [quote.organization.id],
        originatorIds: filters.originatorIds,
        statuses: filters.statuses,
        laneFilter: filters.laneFilter,
        equipmentTypes: filters.equipmentTypes,
      },
      first: 6,
    },
    fetchPolicy: 'cache-and-network',
  });

  const quotes = getHistory(quote, data);

  const { shipments } = useGetShipments({
    equipmentTypes: filters.equipmentTypes,
    laneFilter: filters.laneFilter,
  });

  return {
    quotes,
    shipments,
    loading,
    error,
  };
};
