import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { FlowIcon } from '../flow-icon/FlowIcon';
import { TextField } from '../react-hook-form';
import { Text } from '../text';

const ROOT = makeRootClassName('InlineTextField');
const el = makeElementClassNameFactory(ROOT);

type InlineTextFieldProps<T> = {
  className?: string;
  fieldWrapperClassName?: string;
  isDisabled?: boolean;
  label?: string;
  name: Path<T>;
  save: (p: Path<T>) => void;
  required: boolean;
  type?: 'text' | 'number';
};

export const InlineTextField = <T extends FieldValues>({
  className,
  fieldWrapperClassName,
  isDisabled,
  name,
  label,
  save,
  required,
  type = 'text',
}: InlineTextFieldProps<T>) => {
  const { watch, control } = useFormContext<T>();
  const value: string = watch(name);

  const onSave = () => {
    save(name);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  const placeholder = label ? `Add ${label.toLowerCase()}` : undefined;

  return (
    <div className={clsx(ROOT, className)}>
      <div className={clsx(el`table`, label && 'with-label')}>
        {label && (
          <div className={el`label`}>
            <Text type="body-sm">{label}</Text>
          </div>
        )}
        <div className={el`field-and-icon-wrapper`}>
          <TextField
            isDisabled={isDisabled}
            aria-label={label}
            onKeyDown={handleKeyDown}
            control={control}
            name={name}
            onBlur={onSave}
            size="medium"
            fieldWrapperClassName={clsx(
              el`input-wrapper`,
              value ? 'VALID' : 'INVALID',
              required && 'REQUIRED',
              fieldWrapperClassName
            )}
            transparentWithoutFocus
            placeholderClassName={clsx(
              el`placeholder`,
              value ? 'VALID' : 'INVALID',
              required && 'REQUIRED'
            )}
            placeholder={placeholder}
            className={el`field-wrapper`}
            type={type}
          />
          <FlowIcon fieldPath={name} />
        </div>
      </div>
    </div>
  );
};
