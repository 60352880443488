export type OrderSubmissionError = {
  orderRef: string;
  description: string;
};

export type OrderSubmissionFailureMessage = {
  failedOrderStr: string;
  description: string;
};

export const parseOrderSubmissionErrors = (
  errors: OrderSubmissionError[]
): OrderSubmissionFailureMessage => {
  const numFailures = errors.length;

  if (numFailures === 1) {
    return {
      failedOrderStr: `order ${errors[0].orderRef}`,
      description: errors[0].description,
    };
  }

  const allSameError = errors.every(
    (e) => e.description === errors[0].description
  );

  if (allSameError) {
    return {
      failedOrderStr: `orders`,
      description: errors[0].description,
    };
  }

  return {
    failedOrderStr: `${numFailures} orders`,
    description: errors
      .map((e) => `${e.orderRef}: ${e.description}`)
      .join('\n'),
  };
};
