import { AuthorizeFrontNotice } from '@/components/AuthorizeFrontNotice';
import { AuthorizeUnderlyingInboxNotice } from '@/components/AuthorizeUnderlyingInboxNotice';
import { AuthorizeGmailNotice } from '@shared/components/authorizeInstructions/AuthorizeGmailNotice';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import {
  AuthorizedIntegrationContext,
  AuthorizedIntegrationContextType,
} from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useHasAuthorizedIntegration } from '@shared/contexts/pluginContexts/useHasAuthorizedIntegration';
import {
  AuthTokenType,
  FrontProvider,
  IntegrationCredentialsType,
  UsageIndicator,
} from '@shared/generated/graphql';
import useOrganizationSettings from '@shared/graphql/hooks/organization/useOrganizationSettings';
import { useOrganizationHasCredential } from '@shared/graphql/hooks/useOrganizationHasCredential';

type FrontAuthorizatedIntegrationProviderProps = {
  children: JSX.Element;
};

export const FrontAuthorizedIntegrationProvider = ({
  children,
}: FrontAuthorizatedIntegrationProviderProps) => {
  const { defaultOrgId } = useMeContext();

  const { settings } = useOrganizationSettings({
    id: defaultOrgId ?? '',
  });

  const emailProviderAuthorization = useHasAuthorizedIntegration({
    type:
      settings?.front?.emailProvider === FrontProvider.OUTLOOK
        ? AuthTokenType.MICROSOFT_GRAPH_API
        : AuthTokenType.GMAIL_API,
  });

  const frontAuthorization = useOrganizationHasCredential({
    organizationId: defaultOrgId ?? '',
    type: IntegrationCredentialsType.FRONT,
    usageIndicator: UsageIndicator.PRODUCTION,
  });

  const refetchBoth = async () => {
    await emailProviderAuthorization.refetch();
    await frontAuthorization.refetch();
  };

  const value: AuthorizedIntegrationContextType = {
    authorizedEmail: emailProviderAuthorization.authorizedEmail,
    authorized:
      emailProviderAuthorization.authorized &&
      (frontAuthorization.hasCredential ?? false),
    loading: emailProviderAuthorization.loading || frontAuthorization.loading,
    refetch: refetchBoth,
    integrationName: IntegrationCredentialsType.FRONT,
    renderAuthInstructions: () => (
      <AuthorizeFrontIntegrationsNotice
        frontIsAuthorized={frontAuthorization.hasCredential ?? false}
        emailIsAuthorized={emailProviderAuthorization.authorized}
        emailProvider={settings?.front?.emailProvider}
        refetch={refetchBoth}
      />
    ),
  };

  return (
    <AuthorizedIntegrationContext.Provider value={value}>
      {children}
    </AuthorizedIntegrationContext.Provider>
  );
};

type AuthorizeFrontIntegrationsNoticeProps = {
  frontIsAuthorized: boolean;
  emailIsAuthorized: boolean;
  emailProvider?: FrontProvider;
  refetch: () => void;
};
export const AuthorizeFrontIntegrationsNotice = (
  p: AuthorizeFrontIntegrationsNoticeProps
) => {
  if (!p.frontIsAuthorized) return <AuthorizeFrontNotice />;
  if (!p.emailIsAuthorized) {
    if (p.emailProvider === FrontProvider.OUTLOOK) {
      return <AuthorizeUnderlyingInboxNotice />;
    } else {
      return <AuthorizeGmailNotice />;
    }
  }
  return null;
};
