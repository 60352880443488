import { Text } from '@shared/components';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useGetConversationExternalMessagesQuery } from '@shared/generated/graphql';
import { toConversationContributions } from '@shared/graphql/fromFragments/externalMessage';
import {
  Conversation,
  ConversationContribution,
  ConversationContributionRole,
} from '@shared/types/conversation';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import { formatElapsedTime } from 'clerk_common/stringification/times';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

const ROOT = makeRootClassName('ConversationTranscript');
const el = makeElementClassNameFactory(ROOT);

export const ConversationTranscript = ({
  conversation,
}: {
  conversation: Conversation;
}) => {
  const { defaultOrgId } = useMeContext();
  const { data } = useGetConversationExternalMessagesQuery({
    variables: {
      input: {
        conversationId: conversation.id,
        ...(defaultOrgId && { organizationIds: [defaultOrgId] }),
      },
    },
    // NOTE(parlato): We should definitely not be polling the external
    // messages table every second
    pollInterval: 1 * 1000,
  });
  const msgs = toConversationContributions(data).filter(
    (m) => m.role !== ConversationContributionRole.SYSTEM
  );
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [msgs]);

  return (
    <div ref={containerRef} className={el`conversation-contributions`}>
      {msgs?.map((m) => (
        <ConversationContributionBubble
          key={m.id}
          contribution={m}
          conversationStartTime={conversation.createdAt}
        />
      ))}
      {msgs.length === 0 && (
        <Text type="body-xs" className="text-gray-500">
          Waiting for transcript...
        </Text>
      )}
    </div>
  );
};

type ConversationContributionBubbleProps = {
  contribution: ConversationContribution;
  conversationStartTime: Date;
};

const ConversationContributionBubble = ({
  contribution,
  conversationStartTime,
}: ConversationContributionBubbleProps) => {
  const roleClassName =
    contribution.role === ConversationContributionRole.USER
      ? 'USER'
      : 'ASSISTANT';

  const className = clsx(el`conversation-contribution`, {
    [roleClassName]: true,
  });
  return (
    <div className={className}>
      <Text key={contribution.id} type="body-xs">
        {contribution.transcript}
      </Text>
      <Text type="body-xs" className="text-end">
        {formatElapsedTime(conversationStartTime, contribution.createdAt)}
      </Text>
    </div>
  );
};
