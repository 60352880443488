import { svgTrash } from '@shared/assets';
import { Button } from '@shared/components';
import { Autocomplete, TextField } from '@shared/components/react-hook-form';
import {
  STANDARD_REFERENCE_TYPES,
  StandardReferenceType,
} from 'clerk_common/enums';
import { formatOption } from 'clerk_common/stringification/options';
import { Control, FieldValues, Path } from 'react-hook-form';

export type ReferenceNumber = {
  name: string;
  type: {
    label: string;
    value: StandardReferenceType;
  };
};
type ReferenceNumbersProps<T extends FieldValues> = {
  refFieldName: Path<T>;
  refArray: ReferenceNumber[];
  setValue: (name: Path<T>, value: any) => void;
  control: Control<T>;
  watch: (name: Path<T>) => any;
};
export function ReferenceNumbers<T extends FieldValues>(
  p: ReferenceNumbersProps<T>
) {
  const requiredProps = { rules: { required: true } };
  const refTypeOptions = STANDARD_REFERENCE_TYPES.map((type) => ({
    label: formatOption(type),
    value: type,
  })).sort((a, b) => a.label.localeCompare(b.label));

  const addReference = () => {
    p.setValue(p.refFieldName, [...p.refArray, { name: '', type: undefined }]);
  };

  const removeReference = (index: number) => {
    p.setValue(
      p.refFieldName,
      p.refArray.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="flex w-full flex-col items-start gap-2">
      {p.refArray.map((_, i, arr) => {
        const type = p.watch(`${p.refFieldName}.${i}.type` as Path<T>);
        return (
          <div
            className="flex w-full flex-row gap-2"
            key={`${i}-of-${arr.length}`}
          >
            <Autocomplete
              label={type ? 'Type' : 'Reference Type'}
              name={`${p.refFieldName}.${i}.type` as Path<T>}
              control={p.control}
              options={refTypeOptions}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              size="small"
              {...requiredProps}
            />
            {type && (
              <div className="w-2/3">
                <TextField
                  label="Appears as"
                  name={`${p.refFieldName}.${i}.name` as Path<T>}
                  control={p.control}
                  size="small"
                />
              </div>
            )}
            <Button
              variant="tertiary"
              onPress={() => {
                removeReference(i);
              }}
              icon={svgTrash}
              className="mt-[24px] h-[33px] w-[33px]"
            />
          </div>
        );
      })}
      <Button variant="tertiary" onPress={addReference}>
        {p.refArray.length === 0
          ? '+ Add a Reference'
          : '+ Add Another Reference'}
      </Button>
    </div>
  );
}
