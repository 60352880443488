import {
  ParsedNotifications,
  useEmailThreadContext,
} from '@shared/contexts/pluginContexts/EmailThreadContext';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { DrawerTabValue } from 'clerk_common/constants/urls/queryParams';
import { ORDERS_ROUTE, QUOTES_ROUTE } from 'clerk_common/constants/urls/routes';
import {
  extractTokenizedValueFromEmailBody,
  TokenizableEntityType,
} from 'clerk_common/email-tokens/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function parseNotifications(body?: string) {
  if (!body) return;

  const idTypes: Record<keyof ParsedNotifications, TokenizableEntityType> = {
    quoteId: TokenizableEntityType.QUOTE_ID,
    orderId: TokenizableEntityType.ORDER_ID,
  };

  return Object.entries(idTypes).reduce((acc, [id, type]) => {
    return {
      ...acc,
      [id]: extractTokenizedValueFromEmailBody(type, body) ?? undefined,
    };
  }, {});
}

const BASE_PATH = '/';

type AutomaticNavigatorProps = {
  children: React.ReactNode;
};
export function AutomaticNavigator({ children }: AutomaticNavigatorProps) {
  const navigate = useNavigate();
  const { notifications, thisItem } = useEmailThreadContext();
  const { track } = useAnalytics();

  useEffect(() => {
    if (!thisItem?.id) return;
    const path = determinePath({ notifications });
    if (path !== BASE_PATH) {
      track('Auto-navigate based on email context', { ...notifications });
    }
    navigate(path);
  }, [thisItem?.id]);

  return <>{children}</>;
}

type DeterminePathParams = {
  notifications?: ParsedNotifications;
};

function determinePath({ notifications }: DeterminePathParams) {
  if (notifications?.quoteId) {
    return `${QUOTES_ROUTE}/${notifications?.quoteId}/${DrawerTabValue.AUDIT_TRAIL}`;
  }
  if (notifications?.orderId) {
    return `${ORDERS_ROUTE}/${notifications?.orderId}`;
  }
  return BASE_PATH;
}
