import { svgLogoMediumSize, svgSparkles } from '@shared/assets/icons/svgs';
import { CircularProgress } from '@shared/components';
import { Text } from '@shared/components/text';
import { useToast } from '@shared/components/toast';
import { useMeContext } from '@shared/contexts/hooks/useMeContext';
import { useIntercomSupportContext } from '@shared/contexts/IntercomSupportContext';
import { useAuthorizedIntegrationContext } from '@shared/contexts/pluginContexts/AuthorizedIntegrationContext';
import { useEmailThreadContext } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { useAuthContext } from '@shared/contexts/useAuthContext';
import { JobType } from '@shared/generated/graphql';
import { useAnalytics } from '@shared/hooks/useAnalytics';
import { useEmailScanningCue } from '@shared/hooks/useEmailScanningCue';
import { ActionRegistryContextProvider } from '@shared/plugin/contexts/ActionRegistryContext';
import { FeedContextProvider } from '@shared/plugin/contexts/FeedContext';
import { Feed } from '@shared/plugin/features/Feed';
import { FeedFilter } from '@shared/plugin/features/Feed/components/feed-filter/FeedFilter';
import { shouldPromptToAllowScanning } from 'clerk_common/features/emailScanning';
import clsx from 'clsx';
import { useEffect } from 'react';
import { EmailThreadActivity } from './components/email-thread-activity';
import { SharedInboxNotice } from './components/SharedInboxNotice';

const Home = () => {
  const { isAuthenticated } = useAuthContext();
  const { threadJobs, createOrder, createQuote, thisItem } =
    useEmailThreadContext();
  const { me, loading, hasEmailScanning, defaultOrgId } = useMeContext();
  const {
    authorized,
    loading: loadingAuthorizedIntegration,
    renderAuthInstructions,
  } = useAuthorizedIntegrationContext();
  const { sendToast } = useToast();
  const { page, track } = useAnalytics();
  const { handleAddAllowedSender } = useEmailScanningCue();
  useIntercomSupportContext();

  useEffect(() => {
    if (me?.id) {
      page('Home');
    }
  }, [me?.id]);

  useEffect(() => {
    if (createOrder.loading || createQuote.loading) return;

    const error = createOrder.error || createQuote.error;
    if (error) {
      sendToast(error.message, {
        variant: 'error',
        compact: true,
      });
    }
  }, [createOrder.error, createQuote.error]);

  const createJob = async (jobType: JobType) => {
    switch (jobType) {
      case JobType.ORDERS:
        track('Clicked Build Order', {});
        return createOrder.call();
      case JobType.QUOTES:
        track('Clicked Create Quote', {});
        return createQuote.call();
      default:
        throw new Error(`Invalid job type: ${jobType}`);
    }
  };

  const isLoggedIn = me?.email && isAuthenticated;
  const showTransactions = authorized && !loading && isLoggedIn;

  const disableTransactions = Boolean(showTransactions && !thisItem?.id);
  const shouldAuthorize =
    !loading && isLoggedIn && !authorized && !loadingAuthorizedIntegration;

  const handleSendToVooma = async (jobType: JobType) => {
    const job = await createJob(jobType);

    if (job) threadJobs.storeJobType(jobType, job?.id);

    const contactEmail = thisItem?.sender.emailAddress;
    if (job && contactEmail && defaultOrgId && hasEmailScanning) {
      const shouldAddSender = shouldPromptToAllowScanning(
        contactEmail,
        job.source,
        defaultOrgId,
        me?.features
      );
      if (shouldAddSender) {
        const shouldToast = false;
        handleAddAllowedSender(contactEmail, shouldToast);
        sendToast(`Your ${jobType.toLowerCase().slice(0, -1)} is processing.`, {
          description: `Future ${jobType.toLowerCase()} from this sender will be available instantly.`,
          autoHideDuration: 3000,
          isDismissible: true,
        });
      }
    }
  };

  if (!thisItem?.id) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <div className="flex items-center">
          <span className="mx-1 inline-block fill-yellow-400">
            {svgSparkles}
          </span>
          <span className="mx-2 inline-block">{svgLogoMediumSize}</span>
          <span className="mx-1 inline-block fill-yellow-400">
            {svgSparkles}
          </span>
        </div>
        <Text className="p-4 text-gray-600" type="body-sm">
          {`Select email to experience Vooma's magic`}
        </Text>
      </div>
    );
  }

  return (
    <ActionRegistryContextProvider>
      <FeedContextProvider
        handleSendToVooma={handleSendToVooma}
        disableTransactions={disableTransactions}
      >
        <div className="flex h-full flex-col bg-gray-50">
          {!shouldAuthorize && (
            <div className={clsx('bg-white pb-2 drop-shadow')}>
              <FeedFilter />
            </div>
          )}
          {!shouldAuthorize && <SharedInboxNotice />}
          <ul
            className="flex flex-col gap-y-[2px]"
            style={{ listStyle: 'none' }}
          >
            {loading && (
              <div className="flex h-48 w-full flex-col items-center justify-center gap-4">
                <CircularProgress size="small" />
              </div>
            )}
            {shouldAuthorize && renderAuthInstructions()}
            <EmailThreadActivity />
          </ul>
          {showTransactions && <Feed />}
        </div>
      </FeedContextProvider>
    </ActionRegistryContextProvider>
  );
};

export default Home;
