import {
  ConversationMetadata,
  ConversationStatus,
  ConversationSummary,
  ConversationType,
  PhoneConversationMetadata,
} from '@shared/generated/graphql';
import { isNil } from 'lodash';

export type Conversation = {
  id: string;
  createdAt: Date;
  type: ConversationType;
  status: ConversationStatus;
  purposeDescription?: string;
  user?: {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  };
  summary?: ConversationSummary;
  metadata?: ConversationMetadata;
};

export enum ConversationContributionRole {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}

export type ConversationContribution = {
  id: string;
  createdAt: Date;
  role: ConversationContributionRole;
  transcript: string;
};

export function isPhoneConversationMetadata(
  metadata?: ConversationMetadata
): metadata is PhoneConversationMetadata {
  return (
    !isNil(metadata) &&
    'fromPhoneNumber' in metadata &&
    'toPhoneNumber' in metadata
  );
}
